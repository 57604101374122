import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PathRoute from 'src/common/PathRoute';
import { headerActionType } from 'src/redux/actions/actionTypes';

function NearbyAcademy({ isHeader }) {
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const [defaultAcademy, setDefaultAcademy] = useState({});
    const [showSelect, setShowSelect] = useState(false);
    const route = useRouter();
    const routeAcademy = route?.query?.franchise;
    const listSite = useSelector((state) => state.listSiteReducer.listSite);
    const country =
        useSelector((state) => state.headerReducer.country) || Constants.UK;
    const displayRouteAcademy = listSite.find(
        (item) => routeAcademy === item?.ms_alias,
    );
    const defaultAcademyChange = useSelector(
        (state) => state.headerReducer.academy,
    );

    const headerReducer = useSelector((state) => state.headerReducer);
    useEffect(() => {
        setDefaultAcademy(
            JSON.parse(localStorage.getItem('defaultAcademy')) ||
                defaultAcademyChange,
        );
    }, [headerReducer]);

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    function handleClick(event) {
        const { target } = event;
        if (!wrapperRef.current.contains(target)) {
            setShowSelect(false);
        }
    }

    function onChooseLocation() {
        setShowSelect(false);
    }
    const renderNameNotHeader = () => {
        if (
            !isEmpty(defaultAcademy) &&
            isEmpty(defaultAcademyChange) &&
            isEmpty(displayRouteAcademy)
        ) {
            return defaultAcademy?.ms_name;
        }
        if (!isEmpty(defaultAcademyChange)) {
            return defaultAcademyChange?.ms_name;
        }
        if (!isEmpty(defaultAcademy) && !isEmpty(displayRouteAcademy)) {
            return displayRouteAcademy?.ms_name;
        }
        if (isEmpty(defaultAcademy) && !isEmpty(displayRouteAcademy)) {
            return displayRouteAcademy?.ms_name;
        }
        return 'Find your nearest academy';
    };

    const renderNameHeader = () => {
        if (
            !isEmpty(defaultAcademy) &&
            isEmpty(defaultAcademyChange) &&
            isEmpty(displayRouteAcademy)
        ) {
            return defaultAcademy?.ms_name;
        }
        if (!isEmpty(defaultAcademyChange)) {
            return defaultAcademyChange?.ms_name;
        }
        if (!isEmpty(defaultAcademy) && !isEmpty(displayRouteAcademy)) {
            return displayRouteAcademy?.ms_name;
        }
        if (isEmpty(defaultAcademy) && !isEmpty(displayRouteAcademy)) {
            return displayRouteAcademy?.ms_name;
        }
        return 'Find an academy';
    };

    const renderBaseLocation = () => {
        if (
            !isEmpty(defaultAcademy) &&
            isEmpty(defaultAcademyChange) &&
            isEmpty(displayRouteAcademy)
        ) {
            return defaultAcademy?.ms_name;
        }
        if (!isEmpty(defaultAcademyChange)) {
            return defaultAcademyChange?.ms_name;
        }
        if (!isEmpty(defaultAcademy) && !isEmpty(displayRouteAcademy)) {
            return displayRouteAcademy?.ms_name;
        }
        if (isEmpty(defaultAcademy) && !isEmpty(displayRouteAcademy)) {
            return displayRouteAcademy?.ms_name;
        }
        if (
            isEmpty(defaultAcademy) &&
            isEmpty(defaultAcademyChange) &&
            isEmpty(displayRouteAcademy)
        ) {
            return <h3>You need choose a location</h3>;
        }
        return <h3>You need choose a location</h3>;
    };

    //! Render
    return (
        <div className="custom-select" ref={wrapperRef}>
            <div
                className="select-selected displayNearby"
                onClick={() => {
                    if (defaultAcademy && defaultAcademy.ms_id) {
                        setShowSelect(!showSelect);
                    } else {
                        setShowSelect(false);
                        dispatch({
                            type: headerActionType.CHANGE_LOCATION,
                        });
                        localStorage.setItem(
                            'defaultAcademy',
                            JSON.stringify(defaultAcademyChange),
                        );
                    }
                }}>
                {!isHeader && renderNameNotHeader()}
                {isHeader && renderNameHeader()}
            </div>
            <div className={`tooltip ${showSelect ? '' : 'select-hide'}`}>
                <div className="wrap">
                    <div className="wraphead">
                        <h3>
                            Based on your location, your selected academy is
                            {'  '}
                            <span className="name">{renderBaseLocation()}</span>
                        </h3>

                        <a
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault();
                                onChooseLocation();
                            }}>
                            <FontAwesomeIcon
                                icon={faTimes}
                                style={{
                                    color: 'grey',
                                }}
                            />
                        </a>
                    </div>
                    {Object.values(listSite).length !== 1 ? (
                        <>
                            <div className="wrapcontent">
                                <button onClick={onChooseLocation}>Yes</button>
                                <button
                                    onClick={() => {
                                        setShowSelect(false);
                                        dispatch({
                                            type: headerActionType.CHANGE_LOCATION,
                                        });
                                    }}>
                                    Another location
                                </button>
                            </div>
                            <p>
                                If you wish to change this please choose
                                &apos;Another Location&apos;
                            </p>
                            <div className="wraphead">
                                <span>
                                    Want to start over?{' '}
                                    <h3
                                        className="name"
                                        style={{
                                            cursor: 'pointer',
                                            display: 'inline',
                                        }}
                                        onClick={() => {
                                            localStorage.removeItem(
                                                'defaultAcademy',
                                            );
                                            localStorage.removeItem(
                                                'longitude',
                                            );
                                            localStorage.removeItem('latitude');
                                            localStorage.removeItem(
                                                'masterData2',
                                            );
                                            localStorage.removeItem(
                                                'childActive2',
                                            );
                                            localStorage.removeItem(
                                                'childNumber',
                                            );
                                            window.location.href =
                                                PathRoute.Home(country);
                                        }}>
                                        Clear location
                                    </h3>
                                </span>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}

export default NearbyAcademy;
